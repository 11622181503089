function runCustomSettings() {
    console.log('Ofidirect Custom Settings');
    // Cambio el path a redireccionar por defecto despues de Login
    store.commit('changeStoreValue',{stateField:"afterLoginPath",value:'home'});
    store.commit('changeStoreValue',{stateField:"useFacebook",value:false});
    store.commit('changeStoreValue',{stateField:"useTwitter",value:false});
    store.commit('changeStoreValue',{stateField:"useAllGenders",value:false});
}

// Funcion de Scoring Customizada para Ofidirect...
function score(target, query, fuzzyFactor) {
    //console.log("Custom Ofidirect");
    // If the string is equal to the word, perfect match.
    if (query === '')
        return 0;
    if (target === query)
        return 1;

    let runningScore = 0;
    let targetLower = target.toLowerCase();
    let targetLen = target.length;
    let queryLower = query.toLowerCase();
    let queryLen = query.length;
    let startAt = 0;
    let fuzzies = 1;
    let queryWords = queryLower.split(" ")
    let haveSubWords = 0;
    for(let queryWord of queryWords)
        if( targetLower.indexOf(queryWord)!=-1)
            haveSubWords +=1;
    if(haveSubWords!=queryWords.length)
        return 0;
    // Calculate fuzzy factor
    fuzzyFactor = fuzzyFactor ? 1 - fuzzyFactor : 0;

    // Walk through query and add up scores.
    // Code duplication occurs to prevent checking fuzziness inside for loop
    for (let i = 0; i < queryLen; i += 1) {
        // Find next first case-insensitive match of a character.
        let idxOf = targetLower.indexOf(queryLower[i], startAt);

        if (idxOf === -1) {
            if (fuzzyFactor) {
                fuzzies += fuzzyFactor;
            } else {
                return 0;
            }
        } else {
            let charScore = 0;
            if (startAt === idxOf) {
                // Consecutive letter & start-of-string Bonus
                charScore = 0.7;
            } else {
                charScore = 0.1;

                // Acronym Bonus
                // Weighing Logic: Typing the first character of an acronym is as if you
                // preceded it with two perfect character matches.
                if (target[idxOf - 1] === ' ') {
                    charScore += 0.8;
                }
            }

            // Same case bonus.
            if (target[idxOf] === query[i]) {
                charScore += 0.1;
            }

            // Update scores and startAt position for next round of indexOf
            runningScore += charScore;
            startAt = idxOf + 1;
        }
    }

    // Reduce penalty for longer strings.
    let finalScore = 0.5 * (runningScore / targetLen + runningScore / queryLen) / fuzzies;

    if ((queryLower[0] === targetLower[0]) && (finalScore < 0.85)) {
        finalScore += 0.15;
    }

    if ((queryLower[0] === targetLower[0]) && (finalScore < 0.85)) {
        finalScore += 0.15;
    }


    for(let queryWord of queryWords) {
        if (targetLower.startsWith(queryWord + " ")) {
            finalScore += 0.6;
        } else if (targetLower.startsWith(queryWord))
            finalScore += 0.3;
        else if (targetLower.indexOf(" " + queryWord + " ") != -1)
            finalScore += 0.6;
        else if (targetLower.indexOf(queryWord + " ") != -1 || targetLower.indexOf(queryWord + " "))
            finalScore += 0.2;
    }

    return finalScore;
}
window.runCustomSettings= runCustomSettings;